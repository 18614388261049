import axios from "axios"

export const confirm = async (authToken, code) => {
  const response = await axios.post("https://api.megle.tv/api/confirm", {
    authToken,
    code
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

