import React, { useState, useEffect } from "react";
import { Canvas, Rect, FabricImage, filters, IText, Line } from "fabric";
import './test.css';
import { CirclePicker } from 'react-color';
import './App.css';


const fonts = [
    { label: 'Arial', value: 'Arial' },
    { label: 'Helvetica', value: 'Helvetica' },
    { label: 'Myriad Pro', value: 'Myriad Pro' },
    { label: 'Delicious', value: 'Delicious' },
    { label: 'Verdana', value: 'Verdana' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Courier', value: 'Courier' },
    { label: 'Comic Sans MS', value: 'Comic Sans MS' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Monaco', value: 'Monaco' },
    { label: 'Optima', value: 'Optima' },
    { label: 'Hoefler Text', value: 'Hoefler Text' },
    { label: 'Plaster', value: 'Plaster' },
    { label: 'Engagement', value: 'Engagement' },
    { label: 'Poppins', value: 'Poppins' },
];

const FabricExample = () => {
  const fabricRef = React.useRef(null);
  const bgImageRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const boundaryRect = React.useRef(null);
  const [color, setColor] = useState("#2c8098");
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const [textSettings, setTextSettings] = useState(false);
    const [qty, setQty] = useState(1);
    const [minUnits, setMinUnits] = useState(1);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const textLayerColors = [
        '#000000',
        '#ffffff',
        '#ff0000',
        '#00ff00',
        '#0000ff',
        '#a4d55f',
        '#ff00ff',
        '#00ffff',
    ];

    const saveImage = () => {
        setBtnLoading(true);
        setLoading(true);
        const dataUrl = fabricRef.current.toDataURL({
            format: 'png',
            quality: 1,
            multiplier: 1 / fabricRef.current.getZoom(),
        });
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
            setBtnLoading(false);
            setLoading(false);
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'image.png';
            a.click();
        };
    };

    const saveImage2 = () => {
        // save boundaryRect content, without losing quality
        const boundary = boundaryRect.current;
        const bg_image = bgImageRef.current;

        // console.log bondary 4 corners coordinates like (x,y), (x,y), (x,y), (x,y)
        const boundaryCoords = [
            { x: boundary.left, y: boundary.top },
            { x: boundary.left + boundary.width, y: boundary.top },
            { x: boundary.left + boundary.width, y: boundary.top + boundary.height },
            { x: boundary.left, y: boundary.top + boundary.height },
        ];

        console.log(boundaryCoords);
      //  return;

        fabricRef.current.set("backgroundImage", null);
        boundary.stroke = null;
        boundary.fill = null;



        fabricRef.current.requestRenderAll();
        
        // remove border from bondary
        const dataUrl = fabricRef.current.toDataURL({
            format: 'png',
            quality: 15,
            multiplier: 15 / fabricRef.current.getZoom(),
            left: boundary.left,
            top: boundary.top,
            width: boundary.width,
            height: boundary.height,
        });

        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
            setBtnLoading(false);
            setLoading(false);
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'image.png';
            a.click();
            boundaryRect.current.stroke = "black";
            fabricRef.current.set("backgroundImage", bg_image);
            fabricRef.current.requestRenderAll();
        };
    };


    // useeffect to check if mobile or desktop screen, if mobile put width = 100%



  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new Canvas(canvasRef.current, {
        selection: false,

      });

      // Load background image
    // Load background image
    var img = new Image();
    img.src = 'https://static.vecteezy.com/system/resources/previews/036/391/980/non_2x/ai-generated-t-shirt-mockup-white-blank-t-shirt-front-and-back-views-on-transparent-background-free-png.png';
    img.crossOrigin = "anonymous";
    img.onload = function () {
        const width = img.width;
        const height = img.height;
        fabricRef.current.setWidth(width);
        fabricRef.current.setHeight(height);
        const canvasWidth = fabricRef.current.getWidth();
        const canvasHeight = fabricRef.current.getHeight();

        // Calculate the aspect ratio of the image and canvas
        const imageAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvasWidth / canvasHeight;

        let bgWidth, bgHeight;

        if (imageAspectRatio > canvasAspectRatio) {
            // Image is wider than canvas
            bgWidth = canvasWidth;
            bgHeight = canvasWidth / imageAspectRatio;
        } else {
            // Canvas is wider than image
            bgHeight = canvasHeight;
            bgWidth = canvasHeight * imageAspectRatio;
        }



      var imageElement = document.createElement('img');
      imageElement.src = 'https://as2.ftcdn.net/v2/jpg/03/50/42/65/1000_F_350426511_tFv2JzpTfDEIRVURqH4BQqe38u5GaLxK.jpg';
        imageElement.crossOrigin = "anonymous"; 
      var fImage = new FabricImage(imageElement);
      fImage.scale = 0.5;
      fImage.top = 15;
      fImage.left = 15;
      fImage.repeat = 'repeat';


      
        bgImageRef.current = new FabricImage(img, {
            selectable: false,
            left: (canvasWidth - bgWidth) / 2, // Center the image horizontally
            top: (canvasHeight - bgHeight) / 2, // Center the image vertically
            width: bgWidth,
            height: bgHeight,
            zIndex: 1,
            filters: [new filters.BlendColor({ color: color, mode: "tint", alpha: 0.8 })],
                 // filters: [new filters.BlendImage({ image: fImage, mode: "multiply" })],

        });

        fabricRef.current.set("backgroundImage", bgImageRef.current);
        fabricRef.current.requestRenderAll();
    };

      // Add boundaries
      boundaryRect.current = new Rect({
        top: 200,
        left: 180,
        width: 400,
        height: 300,
        fill: "rgba(255, 255, 255, 0.5)",
        stroke: "black",
        strokeDashArray: [5, 5],
        opacity: 0.2,
        strokeWidth: 2,
        selectable: false,
        evented: false,
        zIndex: 1,
      });
      fabricRef.current.add(boundaryRect.current);

    fabricRef.current.on("mouse:down", (e) => {
        const obj = e.target;
        if (!obj || !obj.type) {
            setActiveImage(null);
            setActiveText(null);
            return;
        }
        if (obj.type === 'image') {
            setActiveImage(obj);
            setActiveText(null);
        } else if (obj.type === 'i-text') {
            setActiveText(obj);
            setActiveImage(null);
        }
    });


      fabricRef.current.on("object:scaling", (e) => {
        var obj = e.target;
        var boundary = boundaryRect.current;

        // Check if object is moving outside boundary
        if (obj.left < boundary.left) {
          obj.left = boundary.left;
        } else if (obj.left + obj.width * obj.scaleX > boundary.left + boundary.width) {
          obj.left = boundary.left + boundary.width - obj.width * obj.scaleX;
        }

        if (obj.top < boundary.top) {
          obj.top = boundary.top;
        } else if (obj.top + obj.height * obj.scaleY > boundary.top + boundary.height) {
          obj.top = boundary.top + boundary.height - obj.height * obj.scaleY;
        }

        // if width is bigger than boundary width make it equal to boundary width
        if (obj.width * obj.scaleX > boundary.width) {
          obj.scaleX = boundary.width / obj.width;
          obj.left = boundary.left + boundary.width - obj.width * obj.scaleX;
        }

        if (obj.height * obj.scaleY > boundary.height) {
          obj.scaleY = boundary.height / obj.height;
          obj.top = boundary.top + boundary.height - obj.height * obj.scaleY;
        }
      });

      fabricRef.current.on("object:moving", (e) => {
        var obj = e.target;
        var boundary = boundaryRect.current;

        // Update object's position to keep it within the boundary
        if (obj.left < boundary.left) {
          obj.left = boundary.left;
        } else if (obj.left + obj.width * obj.scaleX > boundary.left + boundary.width) {
          obj.left = boundary.left + boundary.width - obj.width * obj.scaleX;
        }

        if (obj.top < boundary.top) {
          obj.top = boundary.top;
        } else if (obj.top + obj.height * obj.scaleY > boundary.top + boundary.height) {
          obj.top = boundary.top + boundary.height - obj.height * obj.scaleY;
        }

        // Clear existing center lines
        fabricRef.current.getObjects('line').forEach((line) => fabricRef.current.remove(line));

        // Centering logic
        const centerThreshold = 10; // Distance in pixels to consider as "center"

        // Horizontal centering
        if (Math.abs(obj.left + obj.width / 2 * obj.scaleX - (boundary.left + boundary.width / 2)) < centerThreshold) {
          obj.left = boundary.left + boundary.width / 2 - obj.width / 2 * obj.scaleX;
          console.log('Object centered horizontally');
        }

        // Vertical centering
        if (Math.abs(obj.top + obj.height / 2 * obj.scaleY - (boundary.top + boundary.height / 2)) < centerThreshold) {
          obj.top = boundary.top + boundary.height / 2 - obj.height / 2 * obj.scaleY;
          console.log('Object centered vertically');
        }

        // Add new center lines if centered
        if (Math.abs(obj.left + obj.width / 2 * obj.scaleX - (boundary.left + boundary.width / 2)) < centerThreshold) {
          const horizontalLine = new Line(
            [boundary.left + boundary.width / 2, boundary.top, boundary.left + boundary.width / 2, boundary.top + boundary.height], {
              stroke: 'red',
              selectable: false,
              evented: false,
              strokeDashArray: [5, 5], // Add this line to make the line dotted
              opacity: 0.5,
            }
          );
          fabricRef.current.add(horizontalLine);

          // remove the line after 0.3s
            setTimeout(() => {
                fabricRef.current.remove(horizontalLine);
            }, 300);
        }

        if (Math.abs(obj.top + obj.height / 2 * obj.scaleY - (boundary.top + boundary.height / 2)) < centerThreshold) {
          const verticalLine = new Line(
            [boundary.left, boundary.top + boundary.height / 2, boundary.left + boundary.width, boundary.top + boundary.height / 2], {
              stroke: 'red',
              selectable: false,
              evented: false,
              strokeDashArray: [5, 5], // Add this line to make the line dotted
              opacity: 0.5,
            }
          );
          fabricRef.current.add(verticalLine);

          setTimeout(() => {
            fabricRef.current.remove(verticalLine);
        }, 300);
        }
      });
    };

    initFabric();

    return () => {
      fabricRef.current.dispose();
    };
  }, []);

  // Update background color without reinitializing the fabric canvas
  useEffect(() => {
    if (bgImageRef.current) {
      bgImageRef.current.filters[0].color = color;
      bgImageRef.current.applyFilters();
      fabricRef.current.requestRenderAll();
    }
  }, [color]);

  const constrainObjectWithinBoundary = (obj) => {
    const boundary = boundaryRect.current;

    // Constrain position
    if (obj.left < boundary.left) {
      obj.left = boundary.left;
    }
    if (obj.top < boundary.top) {
      obj.top = boundary.top;
    }
    if (obj.left + obj.getScaledWidth() > boundary.left + boundary.width) {
      obj.left = boundary.left + boundary.width - obj.getScaledWidth();
    }
    if (obj.top + obj.getScaledHeight() > boundary.top + boundary.height) {
      obj.top = boundary.top + boundary.height - obj.getScaledHeight();
    }

    // Constrain scaling
    if (obj.scaleX < 1) obj.scaleX = 1;
    if (obj.scaleY < 1) obj.scaleY = 1;

    if (obj.left + obj.getScaledWidth() > boundary.left + boundary.width) {
      obj.scaleX = (boundary.width - (obj.left - boundary.left)) / obj.width;
    }
    if (obj.top + obj.getScaledHeight() > boundary.top + boundary.height) {
      obj.scaleY = (boundary.height - (obj.top - boundary.top)) / obj.height;
    }

    obj.setCoords(); // Update object coordinates
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files) {
        Array.from(files).forEach((file) => {
            const reader = new FileReader();
            reader.onload = function (f) {
                const imgElement = new Image();
                imgElement.crossOrigin = "anonymous";
                
                imgElement.src = f.target.result;

                imgElement.onload = function () {
                    const img = new FabricImage(imgElement);

                    // Scale image while preserving its aspect ratio and quality
                    const scaleFactor = Math.min(
                        boundaryRect.current.width / img.width,
                        boundaryRect.current.height / img.height
                    );

                    img.scale(scaleFactor);

                    constrainObjectWithinBoundary(img);  // Ensure the image is within the boundary
                    setImages(prevImages => [...prevImages, img]); // Add the image to the images array
                    fabricRef.current.add(img);
                    fabricRef.current.requestRenderAll();
                };
            };
            reader.readAsDataURL(file);
        });
    }
};


  const handleTextUpload = () => {
    const text = new IText("New Text", {
      left: 100,
      top: 100,
      fill: '#000000',
      fontSize: 20,
    });
    fabricRef.current.add(text);
    setTexts(prevTexts => [...prevTexts, text]);
  };

  const handleDelete = (type, index) => {
    if (type === 'image') {
      fabricRef.current.remove(images[index]);
      setImages(images.filter((_, i) => i !== index));
    } else if (type === 'text') {
      fabricRef.current.remove(texts[index]);
      setTexts(texts.filter((_, i) => i !== index));
    }
    fabricRef.current.requestRenderAll();
  };

  return (
<>
    <aside
id="app-sidebar">
 <div className="color-picker">
 <i className="
        app-title-icon
        fas fa-palette"></i>

    <h3 
    className="app-title"
    >
        Choose color</h3>
    
         <CirclePicker
         id="circle-picker"
         display="flex"
         circleSize={35}
         colors={textLayerColors}
         color={color}
         onChange={(color) => {
         setColor(color.hex);
       console.log(color);
         }}
         />
         </div>
         <div 
            className="image-picker"

         style={{ height: '500px'}}
         >
        <i className="
        app-title-icon
        fas fa-image"></i>




    <i className="
        app-title-icon
        fas fa-tshirt"></i>

    <h3 
    className="app-title"
    >
        Choose quantity</h3>



        <div className="file-upload">

<h3> Click box to upload</h3>
<p>Maximun file size 10mb</p>
<input 
        className="file-input"
        type="file" accept="image/*" onChange={handleImageUpload} multiple />

</div>

      <button 
     className="add-text"
      onClick={handleTextUpload}>Add Text</button>
      <h3>Images</h3>
<div style={{
    display: 'flex',
    flexDirection: 'row',

    overflowY: 'auto'}}>
          {images.map((image, index) => (
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                margin: '5px',
                borderRadius: '5px',
                border: activeImage === image ? '2px solid #2c8098' : '2px solid transparent',
                cursor: 'pointer',
            }}
            onClick={() => {
                // if <i> is clicked alert i
                if (window.event.target.tagName === 'I') {
                    return
                } else {
                fabricRef.current.setActiveObject(image);
                setActiveImage(image);
                setActiveText(null);
                fabricRef.current.requestRenderAll();
                }
                }
            }
            key={index}>
              Img {index + 1}
              <i className="fas fa-trash" onClick={() => handleDelete('image', index)}></i>
            </div>
          ))}
       </div>
        
       {textSettings && activeText ? (
    <div>
        <select
            className="font-select"
            onChange={(e) => {
                if (activeText) {
                    activeText.set("fontFamily", e.target.value);
                    fabricRef.current.requestRenderAll();
                }
            }}
        >
            {fonts.map((font, index) => (
                <option key={index} value={font.value}>
                    {font.label}
                </option>
            ))}
        </select>
    </div>
) : (
    <>
        <h3>Texts</h3>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                overflowY: 'auto',
            }}
        >
            {texts.map((text, index) => (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                        margin: '5px',
                        borderRadius: '5px',
                        border:
                            activeText === text
                                ? '2px solid #2c8098'
                                : '2px solid transparent',
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        if (e.target.tagName !== 'I') {
                            fabricRef.current.setActiveObject(text);
                            setActiveText(text);
                            setActiveImage(null);
                            fabricRef.current.requestRenderAll();
                        }
                    }}
                >
                    Text {index + 1}
                    <div>
                        <i
                            className="fas fa-trash"
                            onClick={() => handleDelete('text', index)}
                        ></i>
                        <i
                            className="fas fa-plus"
                            onClick={() => {
                                setTextSettings(true);
                                if (activeText) {
                                    activeText.set('fill', 'red');
                                    fabricRef.current.requestRenderAll();
                                }
                            }}
                        ></i>
                    </div>
                </div>
            ))}
        </div>
    </>
)}





<div
className="numberstyle-qty-container"
>
<label
style={{
    fontSize: "12px",
    color: "grey",
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
}}
>Minimum quantity: {minUnits}</label>
<div className="numberstyle-qty"><div
onClick={() => {
    if (qty > minUnits) {
        setQty(qty - 1);
    }

}
}
className="qty-btn qty-rem">-</div>
        <input className="numberstyle" 
        type="number" 
        value={qty}
        min={minUnits}
        max="99999"
        onChange={(e) => setQty(parseInt(e.target.value))}
        />

        <div 
        onClick={() => {
            setQty(qty + 1);

        }
    }
        className="qty-btn qty-add">+</div>
        </div>
        
</div>



  

    </div>

<button
style = {{
    zIndex: 1,
}}
onClick={saveImage2}>Save Image</button>
    <button
    className="add-to-cart"
    disabled={btnLoading}
    onClick={saveImage}>
        {btnLoading ? "Loading..." : "Add to cart"}
        </button> 
</aside>

<div id="app-content"
style={
    {
        display: {loading} ? "flex" : "none",
    }

}
>


    <div>
      <canvas 
      style = {{
        filter: "drop-shadow(5px 5px 28px #695d5d)"
      }}
      ref={canvasRef}></canvas>
    
    </div></div>
    </>
  );
};

export default FabricExample;
