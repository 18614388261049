import axios from "axios"

export const items = async (search, category, page) => {
  try {
    const response = await axios.get("https://api.megle.tv/api/items", {
      params: {
        search,
        category,
        page
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Error: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
}
