import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { products } from "../../api/products"
// link and navigate
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SampleNextArrow = (props) => {
  const { onClick } = props
  return (
    <div className='control-btn' onClick={onClick}>
      <button className='next'>
        <i className='fa fa-long-arrow-alt-right'></i>
      </button>
    </div>
  )
}
const SamplePrevArrow = (props) => {
  const { onClick } = props
  return (
    <div className='control-btn' onClick={onClick}>
      <button className='prev'>
        <i className='fa fa-long-arrow-alt-left'></i>
      </button>
    </div>
  )
}
const FlashCard = ({ productItems, addToCart }) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [count, setCount] = useState(0)
  const increment = () => {
    setCount(count + 1)
  }

  const navigate = useNavigate()


  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await products()
      if (result && result.data) {
        setData(result.data)
      }
    }
    fetchData()
  }
  , [])



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <Slider {...settings}>
        {data.map((productItems) => {
          return (
            <div className='box'>
              <div className='product mtop'>
                <div 
                // on click it goes to /product/id
                onClick={() => navigate(`/product/${productItems.id}`)}
                className='img'>
                  {productItems.promo > 0 && <span className='discount'>{productItems.promo}% Off</span>}
                  <img src={`https://api.megle.tv/public/products/${productItems.picture}`} alt='' />
                  <div className='product-like'>
                    <label>{count}</label> <br />
                    <i className='fa-regular fa-heart' onClick={increment}></i>
                  </div>
                </div>
                <div className='product-details'>
                  <h3>{productItems.name}</h3>
                  <div className='rate'>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                  </div>
                  <div className='price'>
                    {productItems.promo > 0 ? (
                      <h4 className={lang === 'ar' ? 'rtl' : ''}> 
                        <span
                  className='discounted-price'
                  >{productItems.price} {t('currency')}</span>
                  {(productItems.price - (productItems.price * productItems.promo) / 100).toFixed(2)} {t('currency')}
                      </h4>
                    ) : (
                    <h4 className={lang === 'ar' ? 'rtl' : ''}>{productItems.price} {t('currency')}</h4>
                    )}
                    {/* step : 3  
                     if hami le button ma click garryo bahne 
                    */}
                    <button onClick={() => addToCart(productItems)}>
                      <i className='fa fa-plus'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

export default FlashCard
