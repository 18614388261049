import axios from "axios"

export const categories = async () => {
  const response = await axios.post("https://api.megle.tv/api/categories2", {
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

