import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./style.css";
import { extractColors } from 'extract-colors'
import { SketchPicker } from 'react-color'


const App = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    storeName: "",
    domainName: "yourdomain",
    email: "",
    phone: "",
    name: "",
    fname: "",
    password: "",
    logo: "",
  });
  const [logo, setLogo] = useState(null);

  const domainRef = useRef(null);
  const inputRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const nameRef = useRef(null);
  const fnameRef = useRef(null);

  const [colors, setColors] = useState([])
  const [primaryColor, setPrimaryColor] = useState("#7f58af")
  const [visibleSketch, setVisibleSketch] = useState(false)


  useEffect(() => {
    // if step2
    if (currentStep === 1) {
      inputRef.current.focus();
    } else if (currentStep === 2) {
        domainRef.current.focus();
    } else if (currentStep === 3) {
        emailRef.current.focus();
    } else if (currentStep === 4) {
        phoneRef.current.focus();
    }
  }, [currentStep]);

  const changeLogo = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target.result;
      setFormData((prevData) => ({ ...prevData, logo: result }));
      extractColors(result).then(colors => {
        console.log(colors)
        // put hex colors in state
        setColors(colors.map(color => color.hex))
      })
    };
    reader.readAsDataURL(file);
  };

  const checkStoreName = () => {
    const storeName = formData.storeName.trim();
    const regex = /^[a-zA-Z0-9-_]+$/;
    return storeName.length > 0 && regex.test(storeName);
  };

  const checkDomainName = () => {
    const domainName = formData.domainName.trim();
    const regex = /^[a-zA-Z0-9-_]+$/;
    return domainName.length > 0 && regex.test(domainName);
  };

  const checkName = () => {
    const name = formData.name.trim();
    const regex = /^[a-zA-Z\s]+$/;
    return name.length > 0 && regex.test(name);
  }

  const checkFname = () => {
    const fname = formData.fname.trim();
    const regex = /^[a-zA-Z\s]+$/;
    return fname.length > 0 && regex.test(fname);
  }

  const checkEmail = () => {
    const email = formData.email.trim();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.length > 0 && regex.test(email);
  };

  const checkPhone = () => {
    const phone = formData.phone.trim();
    const regex = /^[0-9]+$/;
    return phone.length > 0 && regex.test(phone);
  };

  const checkLogo = () => {
    return formData.logo.length > 0;
  };
  const createStore = async () => {
    try {
      // Prepare form data
      const storeData = new FormData();
      const authToken = sessionStorage.getItem("authToken");
      storeData.append("authToken", authToken);
      storeData.append("name", formData.storeName);
      storeData.append("subdomain", formData.domainName);
      storeData.append("email", formData.email);
      storeData.append("phone", formData.phone);
      storeData.append("firstName", formData.name);
      storeData.append("lastName", formData.fname);

      storeData.append("color", primaryColor);

      
      console.log(logo);


      storeData.append("logo", logo);
  
      // Make API call to create store
      const response = await fetch("https://api.solutionpub.dz/api/createStore", {
        method: "POST",
        // Do not set the Content-Type header manually
        body: storeData,
      });
  
      if (response.ok) {
        // Store created successfully
        const result = await response.json();
        console.log(result);
        // Handle success (e.g., navigate to store dashboard)
      } else {
        // Error creating store
        const errorData = await response.json();
        console.error("Error creating store:", errorData.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      createStore();
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      nextStep();
    }
  };


  const steps = [
    {
      content: (
        <>
          <div className="right-side">
            <Lottie
              loop={true}
              autoPlay={true}
              animationData={require("./store.json")}
              className="lottie-animation"
            />
          </div>
          <div className="left-side">
            
            <div className="store-title">
              <h2>Easily create your online store</h2>
              <p className="store-description">
                Welcome to our e-commerce platform! Here, you can create your
                own online store and start selling your products to customers.
                Not only that, but you can also browse and purchase products
                from SolutionPub and earn a percentage from each sale. It's a
                great opportunity to showcase your products and expand your
                business. Get started by clicking the "Start" button below!
              </p>
              <div className="store-description">
                <p>
                  It's completely free to create your online store with
                  SolutionPub! Plus, you'll get a free domain name for your
                  store. Take advantage of this opportunity to showcase your
                  products and expand your business. Get started by clicking the
                  "Start" button below!
                </p>
              </div>
            </div>
            <button
              className="start-button"
              onClick={() => setCurrentStep(1)}
            >
              Start
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Step 1",
      content: (
        <div className="form">
          <div className="store-title">
            <h2>First, let's find a name for your store</h2>
          </div>
          <div className="form2">
            
            <div className="input-field">
              <i className="fas fa-store"></i>
              <input
                type="text"
                placeholder="Name of the store"
                value={formData.storeName}
                onChange={(e) =>
                  setFormData({ ...formData, storeName: e.target.value })
                }
                ref={inputRef}
                onKeyPress={checkStoreName() ? handleKeyPress : null}

              />
            </div>
            <button
              className="next"
              disabled={!checkStoreName()}
              onClick={nextStep}
              style={{ opacity: checkStoreName() ? 1 : 0.3 }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Step 2",
      content: (
        <div className="form">
          <div className="store-title">
            <h2>Customers will access your store through this domain</h2>
          </div>
          <div className="form2">
          <button
              className="previous"
              onClick={previousStep}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className="input-field">
              <i className="fas fa-globe"></i>
              <div className="input-domain">
                <span className="gray-text">www.</span>
                <input
                  className="editable-input"
                  value={formData.domainName}
                  ref={domainRef}
                  onChange={(e) => {
                    setFormData({ ...formData, domainName: e.target.value });
                    e.target.style.width = e.target.value.length + 1 + "ch";
                  }}
                  onKeyPress={checkDomainName() ? handleKeyPress : null}

                />
                <span className="gray-text">.solutionpub.dz</span>
              </div>
            </div>
            <button
              className="next"
              disabled={!checkDomainName()}
              onClick={nextStep}
              style={{ opacity: checkDomainName() ? 1 : 0.3 }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      ),
    },
    {
        title: "Step 3",
        content: (
          <div className="form">
                          <div class="store-title"><h2>
                  Some details about your store
                  </h2></div>
                  <div class="form2">
                  <div className="form">
              <div className="input-field">
                <i className="fas fa-envelope"></i>
                <input
                  type="text"
                  placeholder="Professional Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                    ref={emailRef}
                    // on keypress focus to phone 
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          phoneRef.current.focus();
                        }
                      }}
                />
              </div>
              <div className="input-field">
                <i className="fas fa-phone"></i>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                    ref={phoneRef}
                    // on keypress focus to phone
                    onKeyPress={checkPhone() ? handleKeyPress : null}
                />
                
              </div>
              <div
              className="store-buttons">
              

              <button
              className="previous"
              onClick={previousStep}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
              <button
                className="next"
                disabled={!checkPhone() && !checkEmail()}
                onClick={nextStep}
                style={{ opacity: checkPhone() && checkEmail() ? 1 : 0.3 }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>              </div>

            </div>
                  </div>
         
  
          </div>
        ),
      },
      {
        title: "Step 4",
        content: (
          <div className="form">
                          <div class="store-title"><h2>
                  Some details about you
                  </h2></div>
                  <div class="form2">
                  <div className="form">
              <div className="input-field">
                <i className="fas fa-user"></i>
                <input
                  type="text"
                  placeholder="First Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                    ref={nameRef}
                    // on keypress focus to phone 
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          fnameRef.current.focus();
                        }
                      }}
                />
              </div>
              <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                  type="text"
                  placeholder="Last Name"
                  value={formData.fname}
                  onChange={(e) =>
                    setFormData({ ...formData, fname: e.target.value })
                  }
                    ref={fnameRef}
                    // on keypress focus to phone
                    onKeyPress={checkName() && checkFname() ? handleKeyPress : null}
                />
                
              </div>
              <div
              className="store-buttons">
              

              <button
              className="previous"
              onClick={previousStep}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
              <button
                className="next"
                disabled={!checkName() && !checkFname()}
                onClick={nextStep}
                style={{ opacity: checkName() && checkFname() ? 1 : 0.3 }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>              </div>

            </div>
                  </div>
         
  
          </div>
        ),
      },
    {
      title: "Step 5",
      content: (
        <div className="form">
          <div className="file-upload">
            <h3>Upload your logo</h3>
            <p>Maximum file size 10mb</p>
            <input
              className="file-input"
              onChange={changeLogo}
              type="file"
              multiple={false}
            />
          </div>
          <div className="logo-preview">
            {formData.logo && (
              <img
                style={{ height: "100px" }}
                src={formData.logo}
                alt="Logo"
              />
            )}
          </div>
          <div className="form2">
          <div
              className="store-buttons">
              

              <button
              className="previous"
              onClick={previousStep}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
              <button
                className="next"
                disabled={!checkPhone() && !checkEmail()}
                onClick={nextStep}
                style={{ opacity: checkPhone() && checkEmail() ? 1 : 0.3 }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>              </div>


          </div>
        </div>
      ),
    },
    {
      title: "Step 6",
      content: (
        <div className="form"
        
        >
 <div
 style = {{
  position: "absolute",
  zIndex: "1000",
  opacity: visibleSketch ? 1 : 0,
  transition: "all 0.3s ease",
  transform: "translate(500px, 0)",
 }}
 >
          <SketchPicker
            color={primaryColor}
            
            onChange={(color) => setPrimaryColor(color.hex)}
            />
          </div>
    

<div
style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
}}
>


<div class="store-title"><h2>
                  Choose your primary color
                  </h2></div>
                  <div
                  style ={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "left",
                    width: "60%",
                    flexWrap: "wrap",

                  }}
                  >
                              <div 
           style={{
            backgroundColor: "transparent",
            
            cursor: "pointer",
            width: "50px",
            height: "50px",
            margin: "10px",
            borderRadius: "50%",
            boxShadow: " rgba(50, 50, 93, 0.05) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.05) 0px 18px 36px -18px inset",
            transition: "all 0.3s ease",
            position: "relative",
          }}
          // sketch from color picker
          onClick={() => setVisibleSketch(true)}

          >
            <i 
            style ={{
              position: "absolute",
              bottom: "0",
              right: "0",
              fontSize: "20px",
            }}
            className="fas fa-plus"></i>

          </div>
                  {colors.map((color, index) => (
            <div
              key={index}
              onClick={() => {
                setPrimaryColor(color);
                setVisibleSketch(false);
              }}
              style={{
                backgroundColor: color,
                width: "50px",
                height: "50px",
                margin: "10px",
                borderRadius: "50%",
                cursor: "pointer",
                boxShadow: " rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                opacity: color == primaryColor ? 0.5 : 1,
                transition: "all 0.3s ease",
                transform: color == primaryColor ? "scale(0.9)" : "scale(1)",
              }}
            ></div>
          ))}

         

                  </div>


<div className="preview1" >
<img
src="https://www.bebold.ch/img/home/macbook-white.png" alt="MacBook Computer Apple"/>
<div 
style ={{
  backgroundColor: primaryColor,
}}
className="preview-navbar1">

</div>
<div className="preview-navbar">
  <div className="preview-logo">
    <img src={formData.logo} alt="Logo" />

  </div>
  <div className="preview-search">
    <i 
    style ={{
      color: primaryColor,
    }}
    className="fas fa-search"></i>
  </div>

</div>
<div className="preview-content">
  <div className="preview-product">
  <button
  style = {{
    backgroundColor: primaryColor,
  }}
  >+</button>

    <div className="preview-product-image"></div>
    <div className="preview-product-details">
      <h3>Product Name</h3>
      <p>Product Description</p>
    </div>

  </div>
  <div className="preview-product">
  <button
  style = {{
    backgroundColor: primaryColor,
  }}
  >+</button>
    <div className="preview-product-image"></div>
    <div className="preview-product-details">
      <h3>Product Name</h3>
      <p>Product Description</p>
    </div>

    </div> 
</div>
</div>

</div>













                  <div
              className="store-buttons form2">
              

              <button
              className="previous"
              onClick={previousStep}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
              <button
                className="next"
                disabled={!checkPhone() && !checkEmail()}
                onClick={nextStep}
                style={{ 
                  
                  opacity: checkPhone() && checkEmail() ? 1 : 0.3 }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>              </div>


        </div>
        
      ),
    }
  ];

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={currentStep} timeout={500} classNames="fade">
        <div className="store-container">{steps[currentStep].content}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
