import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import Lottie from "lottie-react";
import lot from "../Animation.json";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
const Cart = ({ CartItem, addToCart, decreaseQty, removeFromCart}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wilaya, setWilaya] = useState(34);
  const [commune, setCommune] = useState(1);
  const [center, setCenter] = useState(1);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isPaymentOnline, setIsPaymentOnline] = useState(false);
  const [isDesk, setIsDesk] = useState(true);
  const [wilayas, setWilayas] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [centers, setCenters] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  // useeffect put wilayas : https://codz.dev/yalidine/wilaya.json
  useEffect(() => {
    const fetchWilayas = async () => {
      try {
        const response = await axios.get("https://codz.dev/yalidine/api.php?o=2");
        setWilayas(response.data.wilayas);
        setCommunes(response.data.communes);
        setCenters(response.data.centers);

        // choose first wilaya
        const wilaya = response.data.wilayas[33];

        setDeliveryPrice(wilaya.desk_fee);

        setWilaya(wilaya.wilaya_id);
        const first_commune = response.data.communes.find((commune) => commune.wilaya_id == wilaya.wilaya_id && commune.has_stop_desk == 1) || response.data.communes[0];
        setCommune(first_commune.id);
        const first_center = response.data.centers.find((center) => center.commune_id == first_commune.id) || response.data.centers[0];
        setCenter(first_center.center_id);


        // commune


      } catch (error) {
        console.error("Failed to fetch wilayas:", error);
      }
    };
    fetchWilayas();
  }, []);


  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const ids = CartItem.map((item) => ({
          id: item.type === "service" ? item.service_id : item.id,
          type: item.type,
        }));

        const response = await axios.post('https://api.solutionpub.dz/api/productsCart', { ids });

        if (response.status === 404) {
          return;
        }

        
        const productDetails = response.data.items.map((product) => {
          const cartItem = CartItem.find((item) =>
            item.type === "service" ? item.service_id === product.id : item.id === product.id
          );

          console.log(cartItem);
          return cartItem
            ? { ...product, qty: cartItem.qty, type: cartItem.type, service_id: cartItem.id }
            : product;
        });

        console.log("pd", CartItem);

        

        setProducts(productDetails);
      } catch (error) {
        const serviceItems = CartItem.filter(item => item.type === "service");
        setProducts(serviceItems);
        console.error("Failed to fetch product details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (CartItem.length > 0) {
      fetchProducts();
    } else {
      setProducts([]);
      setLoading(false);
    }
  }, [CartItem]);

  const totalPrice = products.reduce((price, item) => {
    const itemTotal = item.promo > 0
      ? item.qty * item.price * (1 - item.promo / 100)
      : item.qty * item.price;
    return price + itemTotal;
  }, 0);
  const navigate = useNavigate()

  const checkout = async () => {

    // if there is no sessionstorage authtoken
    if (!sessionStorage.getItem("authToken")) {
      navigate('/login')
      toast.info('You must be logged in', { position: "bottom-center" });
      return;
    }


    const data = {
      authToken: sessionStorage.getItem("authToken"),
      items : CartItem,
      wilaya,
      commune,
      address : isDesk ? "bureau" : address,
      phone: phoneNumber,
      center_id: isDesk ? center : null,
      payment: isPaymentOnline ? "online" : "cash",
      
    };

    console.log("data", data);

    console.log(JSON.stringify(data));
    try {
    if (!isPaymentOnline) {
    
      const response = await axios.post("https://api.solutionpub.dz/api/buy", data);
      console.log(response);
      // if response.success
      if (response.data.success) {
        sessionStorage.removeItem("cart");
        window.location.href = "/";
      }
    
    } else if (isPaymentOnline) {
      const response = await axios.post("https://api.solutionpub.dz/api/pay", data);
      console.log(response);
      // if response.success
      if (response.data.success) {
        sessionStorage.removeItem("cart");
        window.location.href = response.data.url;
      }
    
    }
    } catch (error) {
    console.error("Failed to checkout:", error);
    }
  }


  return (
    <>
    <Helmet>
      <title>Cart - SolutionPUB </title>
      <meta name='description' content='Cart' />
      <meta property='og:title' content='Cart' />
      <meta property='og:description' content='Cart' />

    </Helmet>
   
    <section className='cart-items'>
      <div className='container d_flex'>
        <div className='cart-details'>
          {CartItem.length === 0 && (
            <div>
              <Lottie animationData={lot} style={{ width: 200, height: 200 }} />
            </div>
          )}
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            products.map((item) => (
              <div className='cart-list product d_flex' key={item.id}>
                <div className='img'>
                  <img
                    src={`https://api.solutionpub.dz/public/${item.type === "service" ? "services" : "products"}/${item.picture}`}
                    alt={item.name}
                  />
                </div>
                <div className='cart-details'>
                  <h3>{item.name}</h3>
                  <h4>
                    {item.promo > 0
                      ? `${(item.price - (item.price * item.promo) / 100).toFixed(2)} DA * ${item.qty} `
                      : `${item.price}.00 DA * ${item.qty}`}
                    <span className='discounted-price'>
                      {(item.price * item.qty).toFixed(2)} DA
                    </span>
                    {item.promo > 0 && (
                      <span>
                        {((item.price - (item.price * item.promo) / 100).toFixed(2) * item.qty).toFixed(2)} DA
                      </span>
                    )}
                  </h4>
                </div>
                <div className='cart-items-function'>
                  <div className='removeCart'>
                    <button className='removeCart'
                   onClick={() => removeFromCart(item)}
                    >
                      <i className='fa-solid fa-xmark'></i>
                    </button>
                  </div>
                  <div className='cartControl d_flex'>
                    <button className='incCart' onClick={() => addToCart(item)}>
                      <i className='fa-solid fa-plus'></i>
                    </button>
                    <button className='desCart' onClick={() => decreaseQty(item)}>
                      <i className='fa-solid fa-minus'></i>
                    </button>
                  </div>
                </div>
                <div className='cart-item-price'></div>
              </div>
            ))
          )}
        </div>
        <div className='cart-d'>
          <div className='cart-total product'>
            <h2>Cart Summary</h2>
            <div className=' d_flex'>
              <h4>Subtotal Price :</h4>
              <h3>{totalPrice.toFixed(2)} DA</h3>
            </div>
            <div className=' d_flex'></div>
          <div className=' d_flex'>
            <h4>Delivery Price :</h4>
            <h3>{deliveryPrice.toFixed(2)} DA</h3>
          </div>
          <div className=' d_flex'>
            <h4>Total Price :</h4>
            <h3>{(totalPrice + deliveryPrice).toFixed(2)} DA</h3>
          </div>
        </div>
        <input
          type='text'
          className='input'
          placeholder='Phone Number'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <select
          className='input slct'
          value={wilaya}
          onChange={(e) => {
            setWilaya(e.target.value);
            // get home_fee from wilaya where id = e.target.value
            const wilaya = wilayas.find((wilaya) => wilaya.wilaya_id == e.target.value);
            const first_commune = communes.find((commune) => commune.wilaya_id == e.target.value && commune.has_stop_desk == 1) || communes[0];
            console.log("first_commune", first_commune);
            const first_center = centers.find((center) => center.commune_id == first_commune.id) || centers[0];
            if (isDesk){
            setDeliveryPrice(wilaya.desk_fee)

            }else {
            setDeliveryPrice(wilaya.home_fee)
            }

            setCommune(first_commune.id);
            setCenter(first_center.center_id);

          }}
        >
          {wilayas.map((wilaya) => (
            <option key={wilaya.id} value={wilaya.wilaya_id}>
              {wilaya.wilaya_name}
            </option>
          ))}
        </select>


        <select
            className='input'
            value={commune}
            onChange={(e) => {
              setCommune(e.target.value);
              // get home_fee from wilaya where id = e.target.value
              const commune = communes.find((commune) => commune.id == e.target.value);
              const first_center = centers.find((center) => center.commune_id == e.target.value);
            
              setCenter(first_center.center_id);
            }}
          >
            {isDesk ? (
              communes
                .filter((commune) => commune.wilaya_id == wilaya && commune.has_stop_desk == 1) // Filter communes based on wilaya_id and has_stop_desk
                .map((commune) => (
                  <option key={commune.id} value={commune.id}>
                    {commune.name}
                  </option>
                ))
            ) : (
              communes
                .filter((commune) => commune.wilaya_id == wilaya) // Filter communes based on wilaya_id
                .map((commune) => (
                  <option key={commune.id} value={commune.id}>
                    {commune.name}
                  </option>
                ))
            )}
          </select>


{isDesk && (
          <select
            className='input'
            value={center}
            onChange={(e) => {
              setCenter(e.target.value);
              // get home_fee from wilaya where id = e.target.value
              const center = centers.find((center) => center.center_id == e.target.value);
            }}
          >
            {centers
              .filter((center) => center.commune_id == commune) // Filter centers based on commune_id
              .map((center) => (
                <option key={center.center_id} value={center.center_id}>
                  {center.name}
                </option>
              ))}
          </select>
        )}


        <div className='t_flex'>
          <button
            className={`delivery-type ${isDesk ? "active" : ""}`}
            onClick={() => {
              setIsDesk(true);
              const wil = wilayas.find((wilayaa) => wilayaa.wilaya_id == wilaya);
              setDeliveryPrice(wil.desk_fee);
              
            }
            }
          >
            <i className='fa-solid fa-store'></i>
          </button>
          <button
            className={`delivery-type ${!isDesk ? "active" : ""}`}
            onClick={() => {
              setIsDesk(false);
              const wil = wilayas.find((wilayaa) => wilayaa.wilaya_id == wilaya);
              setDeliveryPrice(wil.home_fee);
              
            }
            }
          >
            <i className='fa-solid fa-home'></i>
          </button>

          </div>


          {!isDesk && (
            <input
              type='text'
              className='input'
              placeholder='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          )}

<h1 style={{ marginTop: 20 }}>
    Payment Method
  </h1>
<div className='t_flex'>

<button
  className={`payment-type ${isPaymentOnline ? "active" : ""}`}
  onClick={() => setIsPaymentOnline(true)}
>
  <img src='/cib_edahabia.png' alt='edahabia' />
</button>
<button
  className={`payment-type ${!isPaymentOnline ? "active" : ""}`}
  onClick={() => setIsPaymentOnline(false)}
>
  <p>Paiement après la livraison</p>
</button>
</div>

<button 
style={{ marginTop: 60 }}
onClick={checkout}
className='btn-primary'>Checkout</button>
        
          </div>

      </div>
      
    </section>
    </>
  );
};

export default Cart;
