import axios from "axios"

export const register = async (username, password, email) => {
    console.log(username, password, email)
  const response = await axios.post("https://api.megle.tv/api/register", {
    username,
    password,
    email
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

