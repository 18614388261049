import axios from "axios"

export const complete = async (token, username, password) => {
  const response = await axios.post("https://api.megle.tv/api/complete", {
    token,
    username,
    password
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

