import axios from "axios"

export const login = async (identifier, password) => {
  const response = await axios.post("https://api.megle.tv/api/login", {
    identifier,
    password,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

